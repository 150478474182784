export class MdlUsuario {

    public usuario: string;
    public nombre: string;
    public paterno: string;
    public materno: string;
    public matricula: string;
    public puesto: string;
    public departamento: string;
    public empresa: string;
    public perfil: number;
    public id: number;
    public id_rh_empleado: number;
    public id_pe_inscripcion: number;
    public id_rpt_tablero_detalle: number;
    public id_clb_membresia: number;
    public imagen: string;

    constructor(
        usuario: string,
        nombre: string,
        paterno: string,
        materno: string,
        matricula: string,
        puesto: string,
        departamento: string,
        empresa: string,
        perfil: number,
        id: number,
        id_rh_empleado: number,
        id_pe_inscripcion: number,
        id_rpt_tablero_detalle: number,
        id_clb_membresia: number,
        imagen: string,
    ) {
        this.usuario = usuario;
        this.imagen = imagen;
        this.nombre = nombre;
        this.paterno = paterno;
        this.materno = materno;
        this.matricula = matricula;
        this.puesto = puesto;
        this.departamento = departamento;
        this.empresa = empresa;
        this.perfil = perfil;
        this.id = id;
        this.id_rh_empleado = id_rh_empleado;
        this.id_pe_inscripcion = id_pe_inscripcion;
        this.id_rpt_tablero_detalle = id_rpt_tablero_detalle;
        this.id_clb_membresia = id_clb_membresia;
    }

    getUsuario(): string {
        return this.usuario;
    }

    getImagen(): string {
        return this.imagen;
    }

    getNombre(): string {
        return this.nombre;
    }

    getPaterno(): string {
        return this.paterno;
    }

    getMaterno(): string {
        return this.materno;
    }

    getMatricula(): string {
        return this.matricula;
    }

    getEmpresa(): string {
        return this.empresa;
    }

    getPuesto(): string {
        return this.puesto;
    }

    getDepartamento(): string {
        return this.departamento;
    }

    getPerfil(): number {
        return this.perfil;
    }

    getId(): number {
        return this.id;
    }

    getId_pe_inscripcion(): number {
        return this.id_pe_inscripcion;
    }
    getId_rh_empleado(): number {
        return this.id_rh_empleado;
    }

    getId_rpt_tablero_detalle(): number {
        return this.id_rpt_tablero_detalle;
    }

    getId_clb_membresia(): number {
        return this.id_clb_membresia;
    }
}