<div class="row clsTitulo">
    <div class="col-10">
        <h3>Listado de Comprobantes</h3>
    </div>
    <div class="col-2">
        <div class="icono">
            <i class="fas fa-list"></i>
        </div>
    </div>
</div>
<ul class="nav nav-tabs" role="tablist">
    <li class="nav-item">
        <a class="nav-link active" id="tab_proceso-tab" data-toggle="tab" href="#tab_proceso" role="tab" aria-controls="tab_proceso" aria-selected="true">Proceso</a>
    </li>
    <li class="nav-item">
        <a class="nav-link" id="tab_ayuda-tab" data-toggle="tab" href="#tab_ayuda" role="tab" aria-controls="tab_ayuda" aria-selected="false">Ayuda</a>
    </li>
</ul>
<div class="tab-content">
    <div class="card">
        <div class="card-body">
            <table class="table table-sm table-hover table-bordered">
                <thead>
                    <tr>
                        <th></th>
                        <th>Fecha</th>
                        <th>Folios</th>
                        <th>Cliente</th>
                        <th>Total</th>
                        <th>Moneda</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of data">
                        <td style="width: 82px;">
                            <button class="btn" (click)="descargaPDF(item.id)" title="Descarga PDF">
                                <i class="far fa-file-pdf"></i>
                            </button>
                            <button class="btn" *ngIf="item.id2" (click)="descargaXML(item.id)" title="Descarga XML Factura">
                                <i class="far fa-file-excel"></i>
                            </button>
                        </td>
                        <td>
                            {{item.fecha}}
                        </td>
                        <td>
                            {{item.folios}}
                        </td>
                        <td>
                            {{item.cliente}}
                        </td>
                        <td style="text-align: right;">
                            {{item.total | number}}
                        </td>
                        <td>
                            {{item.moneda}}
                        </td>

                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>