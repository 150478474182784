import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { DialogUtility } from '@syncfusion/ej2-angular-popups';

@Component({
  selector: 'app-calificaciones',
  templateUrl: './calificaciones.component.html',
  styleUrls: ['./calificaciones.component.scss']
})
export class CalificacionesComponent implements OnInit {
  // variables
  public data: any;
  @BlockUI() blockUI: NgBlockUI;

  constructor(
    private route: ActivatedRoute,
    private http: HttpClient,
    public domSanitizer: DomSanitizer
  ) {
    // CARGAMOS EL LISTADO DE BOLETAS
    this.route.data
      .subscribe((resp: { listado: any[] }) => {
        this.data = JSON.parse(JSON.stringify(resp.listado)).respuesta;
      });
  }

  ngOnInit(): void {

  }

  // ENCRIPTACION DEL ARCHIVO
  private _base64ToArrayBuffer(base64) {
    var binary_string = window.atob(base64);
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  }
  // agregamos 2 id uno para boleta o inscripcion y otra para reporte
  onMostrarPdf(id: number, id_rpt: number): void {
    // ================================================================================
    // Ejecutamos el reporte con los parametros proporcionados
    // ================================================================================
    this.blockUI.start('trabajando...');
    let resonseType: any = 'blob';
    let headers: HttpHeaders = new HttpHeaders({ "Content-Type": "application/json" });
    this.http.post<any>(`${environment.base_url}/reporte/boletaPdf/${id}/${id_rpt}`, {})
      .subscribe(
        response => {
          if (response.numero > 0) {
            // ==========================================================================
            // decodificacion => decodificamos el json que recibimos en response
            // la funcion es "decodeURIComponent" la traemos con el codificado atob
            // simplemente agregamos decodeURIComponent para devolver el xml sin caracteres especiales
            // respetando la codificacion UTF - 8
            // ==========================================================================
            let byteArray = this._base64ToArrayBuffer(response.archivo);
            // Tipo se coloca =>  "application/xml": para reconocerlo como archivo xml archivo de salida
            let blob: any = new Blob([byteArray], { type: 'application/pdf; charset=utf-8' });
            const url = window.URL.createObjectURL(blob);
            window.open(url, '_blank');
          } else {
            DialogUtility.alert({
              title: response.titulo,
              content: response.mensaje,
              showCloseIcon: true,
              closeOnEscape: true,
              animationSettings: { effect: 'Zoom' },
            });
          }
          this.blockUI.stop();
        },
        err => {
          this.blockUI.stop();
          let error: number = err.status;
          switch (error) {
            case 500:
              DialogUtility.alert({
                title: '<span class="dialogo-titulo-error">' + 'Información Arcarius [Problemas]' + ' <i style="color: #fff" class="fas fa-ban"></i></span>',
                content: '<h6>' + '[ Error tu archivo aun no existe, Por Favor de revisar a Control Escolar <i style="color: #FF0033" class="fas fa-exclamation-triangle"></i>    ]' + '</h6>',
                showCloseIcon: true,
                closeOnEscape: true,
                animationSettings: { effect: 'Zoom' },
              });
              break;
            case 404:
              DialogUtility.alert({
                title: '<span class="dialogo-titulo-error">' + 'Información Arcarius [Problemas]' + ' <i style="color: #fff" class="fas fa-ban"></i></span>',
                content: '<h6>' + '[ Error de Sistema, Reporte a Dpt Sistemas <i style="color: #FF0033" class="fas fa-exclamation-triangle"></i>    ]' + '</h6>',
                showCloseIcon: true,
                closeOnEscape: true,
                animationSettings: { effect: 'Zoom' },
              });
              break;
            default:
              DialogUtility.alert({
                title: '<span class="dialogo-titulo-error">' + 'Información Arcarius [Problemas]' + ' <i style="color: #fff" class="fas fa-ban"></i></span>',
                content: '<h6>' + '[ Error de Sistema, Reporte a Control Escolar <i style="color: #FF0033" class="fas fa-exclamation-triangle"></i>    ]' + '</h6>',
                showCloseIcon: true,
                closeOnEscape: true,
                animationSettings: { effect: 'Zoom' },
              });
              break;
          }
        }
      );
  }

}