import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DialogComponent, DialogUtility } from '@syncfusion/ej2-angular-popups';
import { environment } from 'src/environments/environment';
import { ReservacionService } from './services/reservacion.service';
import { isNullOrUndefined as isNOU } from '@syncfusion/ej2-base';
import {
  SelectEventArgs,
  TabComponent,
} from '@syncfusion/ej2-angular-navigations';
import { DropDownListComponent } from '@syncfusion/ej2-angular-dropdowns';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MdlMembresiaReservacion } from './models/MdlMembresiaReservacion';
import { MdlUsuario } from 'src/app/auth/models/MdlUsuario';
import { TextBox } from '@syncfusion/ej2-angular-inputs';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-reservacion',
  templateUrl: './reservacion.component.html',
  styleUrls: ['./reservacion.component.scss'],
})
export class ReservacionComponent implements OnInit,OnDestroy {
  public usuario: MdlUsuario;
  autoRefreshInterval: any;
  public id_reservacion: number = -1;
  //TITULAR
  public titular = true;
  //CONTACTOS
  public v_id_contacto: number = -1;
  public v_nombrecontacto: string = '';
  // SECCION
  public v_id_seccion: number = -1;
  public v_nombreseccion: string = '';
  // DIVISION
  public v_id_division: number = -1;
  public v_nombredivision: string = '';
  // HORARIOS
  public v_id_instructor: number = null;
  public v_nombreinstructor: string = '';
  public v_id_horarioseleccionado: number = -1;
  public v_horarioentrada: Date;
  public v_horariosalida: Date;
  public v_fecha_reservacion: Date;
  public v_enlinea: boolean = false;

  //DATA DE LOS DROPDOWNLIST
  private data_seccion: any;
  private data_division: any;
  private data_horario: any;
  public formatoFecha: string = 'dd/MM/yyyy';
  public customFormat: string = 'HH:mm:ss';
  public fields_seccion: Object = { text: 'descripcion', value: 'id' };
  public fields_division: Object = { text: 'descripcion', value: 'id' };
  public fields_horario: Object = { text: 'descripcion', value: 'id' };

  public showbutton = false;
  public show = false;
  //PARA GUARDAR LA INFORMACION QUE CONTIENE EL HORARIO
  private data_contacto: any;
  public formulario: UntypedFormGroup = new UntypedFormGroup({});

  @ViewChild('horarios') horarios: DropDownListComponent;
  @ViewChild('divisiones') divisiones: DropDownListComponent;
  @ViewChild('secciones') secciones: DropDownListComponent;
  @ViewChild('contacto') contacto: TextBox;
  @ViewChild('contactoname') contactoname: TextBox;
  @ViewChild('instructor') instructor: TextBox;
  @ViewChild('instructorname') instructorname: TextBox;
  @ViewChild('entrada') entrada: TextBox;
  @ViewChild('salida') salida: TextBox;
  @ViewChild('linea') linea: TextBox;
  @ViewChild('division') division: TextBox;
  @ViewChild('dlgReservacionCompleta') dlgReservacionCompleta: DialogComponent;
  @ViewChild('tab') tab: TabComponent;

  public data: any;
  public columns: any;
  public headerText: Object[];

  constructor(
    private router: Router,
    private http: HttpClient,
    private route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private fb: UntypedFormBuilder,
    private reservacionService: ReservacionService
  ) {

    this.usuario = JSON.parse(localStorage.getItem('usuario'));
    this.route.data
    .subscribe((resp: { reservacion: any[] }) => {
      this.titular = JSON.parse(JSON.stringify(resp.reservacion)).respuesta.titular;
    });
    this.http
      .get<any>(
        `${environment.base_url}/membresia/contacto/cargatbcontactos/${this.usuario.id_clb_membresia}`
      )
      .subscribe((resp) => {
        this.data = resp.respuesta.dataSource;
        this.columns = resp.respuesta.columns;
      });
    this.v_id_contacto = this.usuario.id;
    this.cargaListBox();
    this.formulario = this.formBuilder.group(new MdlMembresiaReservacion());
    if(this.titular == false){
      this.show = true;
    }else{
      this.show = false;
    }
  }
  //CARGA EL DROPDOWNLIST DE SECCION
  cargaListBox() {
    this.reservacionService.seccion().subscribe((resp) => {
      this.data_seccion = resp.respuesta;
    });
  }
  //CUANDO SELECCIONA UNA SECCION EL DROPDOWNLIST DE DIVISION VA A CAMBIAR RESPECTO A LA SECCION QUE SELECCIONO
  onChangeSeccion(args): void {
    this.reservacionService
      .division(Number(this.secciones.value))
      .subscribe((resp) => {
        this.data_division = resp.respuesta.divisiones;
      });
      this.v_id_seccion = Number(this.secciones.value);
      this.v_nombredivision = this.secciones.text;

  }

  ngOnDestroy(): void {
    window.location.reload();
  }

  //CUANDO SELECCIONA UNA DIVISION EL DROPDOWNLIST DE HORARIOS VA A CAMBIAR RESPECTO A LA DIVISION QUE SELECCIONO, ADEMAS SE LE ASIGNAN ID EN VARIBLES LOCALES
  onChangeDivision(args): void {
    this.reservacionService
    .horarios(Number(this.divisiones.value))
    .subscribe((resp) => {
      this.data_horario = resp.respuesta.horarios;
    });

    this.v_id_division = Number(this.divisiones.value);
    this.v_nombredivision = this.divisiones.text;

  }
//CUANDO SELECCIONA UN MIEMBRO EL TITULAR PODRA RESERVAR POR ESE MIEMBRO
  public onRowSelected(args: any) {
    if (args.data.estatus == 'SUSPENDIDO') {
      DialogUtility.alert({
        title:
          '<span class="dialogo-titulo-error">Sistema<i style="color: #fff" class="fas fa-ban"></i></span>',
        content: 'El usuario esta suspendido, no puede realizar reservaciones',
        showCloseIcon: true,
        closeOnEscape: true,
        animationSettings: { effect: 'Zoom' },
      });
      this.showbutton = false;
      this.show = false;
    } else {
      this.showbutton = true;
      this.v_id_contacto = args.data.id;
      this.v_nombrecontacto = args.data.nombre;
      this.cargaContacto(args.data.id);
    }
  }
  //CARGA EL LISTADO DEL CONTACTO INDIVIDUAL POR EL ID AL DEL CONTACTO
  cargaContacto(id: number) {
    this.http
      .get<any>(
        `${environment.base_url}/membresia/contacto/cargacontacto/${id}`
      )
      .subscribe((resp) => {
      });
  }

  onCambios(args): void {
    this.http
      .get<any>(
        `${environment.base_url}/membresia/reservacion/cargainfohorarios/${this.horarios.value}`
      )
      .subscribe((resp) => {
        this.data_contacto = resp.respuesta;
        this.v_id_instructor = resp.respuesta.id_crm_instructor;
        this.v_nombreinstructor = resp.respuesta.nombre_instructor;
        this.v_id_horarioseleccionado = Number(this.horarios.value);
        this.v_horarioentrada = resp.respuesta.entrada;
        this.v_horariosalida = resp.respuesta.salida;
        this.v_enlinea = resp.respuesta.enlinea;
      });
  }

  ngOnInit(): void {
    this.headerText = [
      { text: 'Seccion' },
      { text: 'División' },
      { text: 'Horario' },
    ];
  }

  //FORMULARIO RESERVACIONES
  public btnClicked(e: any): void {
    switch (e.target.id) {
      case 'division':
        if (this.secciones.value <= 0 || this.secciones.value == null) {
          document.getElementById('err1').innerText = '*Seleccione una seccion';
        } else {
          this.tab.enableTab(1, true);
          this.tab.enableTab(0, false);
          document.getElementById('err1').innerText = '';
          document.getElementById('err2').innerText = '';
        }
        break;
      case 'horarios':
        if (this.divisiones.value <= 0 || this.secciones.value == null) {
          document.getElementById('err2').innerText =
            '*Seleccione una division';
        } else {
          this.tab.enableTab(2, true);
          this.tab.enableTab(1, false);
          document.getElementById('err2').innerText = '';
        }
        break;
      case 'confirmacion':
        if (this.horarios.value <= 0 || this.horarios.value == null) {
          document.getElementById('err3').innerText = '*Seleccione un horario';
        } else {
          this.formulario = this.fb.group({
            id: [-1],
            id_clb_membresia_contacto: [this.v_id_contacto],
            id_clb_seccion_division: [this.v_id_division],
            id_crm_instructor: [this.v_id_instructor],
            entrada: [new Date(this.v_horarioentrada)],
            salida: [new Date(this.v_horariosalida)],
            enlinea: [this.v_enlinea]
          });
          this.btnGuardarReservacion();
          document.getElementById('err3').innerText = '';
        }

        break;
      case 'regresararea':
        this.tab.enableTab(0, true);
        this.tab.select(0);
        this.tab.enableTab(1, false);
        break;
      case 'regresardivision':

        this.tab.enableTab(1, true);
        this.tab.select(1);
        this.tab.enableTab(2, false);
        break;
      case 'regresarhorario':
        this.tab.enableTab(2, true);
        this.tab.select(2);
        this.tab.enableTab(3, false);
        break;
    }
  }
  public tabSelecting(e: SelectEventArgs): void {
    if (e.isSwiped) {
      e.cancel = true;
    }
  }
  public btnGuardarReservacion(): void {
    this.reservacionService
      .guardar(this.formulario.value as MdlMembresiaReservacion)
      .subscribe((resp) => {
        let respuesta = JSON.parse(JSON.stringify(resp));
         this.id_reservacion = respuesta.numero;
        if (respuesta.numero < 0) {
          DialogUtility.alert({
            title:
              '<span class="dialogo-titulo-error">' +
              respuesta.titulo +
              ' <i style="color: #fff" class="fas fa-ban"></i></span>',
            content: JSON.parse(JSON.stringify(resp)).mensaje,
            showCloseIcon: true,
            closeOnEscape: true,
            animationSettings: { effect: 'Zoom' },
          });
        } else {
            localStorage.setItem('id_reservacion', JSON.parse(String(this.id_reservacion)));
            this.router.navigate(['pages/confirmacionreserva']);
            this.horarios.value = null;
            this.divisiones.value = null;
            this.secciones.value = null;
            this.tab.enableTab(0, true);
            this.tab.enableTab(1, false);
            this.tab.enableTab(2, false);
            this.tab.enableTab(3, false);
            this.tab.select(0);

        }
      });
  }
}
