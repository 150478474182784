import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ErroresService } from '../../../shared/errores.service';
import { Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ComprobantesService {

  constructor(
    private http: HttpClient,
    private errores: ErroresService
  ) { }

  getListadoComprobantes(): Observable<any> {
    let id = JSON.parse(localStorage.getItem('usuario')).id_pe_inscripcion;
    return this.http.get<any>(`${environment.base_url}/escolar/control/alumno/listado_comprobantes/${id}`)
      .pipe(
        catchError(error => {
          return throwError(this.errores.getErrores(error));
        })
      );
  }

  public descargaPDF(id: number): Observable<any> {
    let resonseType: any = 'blob';
    let headers: HttpHeaders = new HttpHeaders({ "Content-Type": "application/json" });
    return this.http.post<any>(`${environment.base_url}/escolar/control/alumno/descargaPDF/${id}`, {}, { responseType: resonseType, headers: headers });
  }

  public descargaXML(id: number): Observable<any> {
    let resonseType: any = 'blob';
    let headers: HttpHeaders = new HttpHeaders({ "Content-Type": "application/json" });
    return this.http.post<any>(`${environment.base_url}/escolar/control/alumno/descargaXML/${id}`, {}, { responseType: resonseType, headers: headers });
  }

}
