import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ComprobantesService } from './services/comprobantes.service';

@Component({
  selector: 'app-comprobantes',
  templateUrl: './comprobantes.component.html',
  styleUrls: ['./comprobantes.component.scss']
})
export class ComprobantesComponent implements OnInit {

  public data: any;

  constructor(
    private route: ActivatedRoute,
    private servicio: ComprobantesService
  ) { }

  ngOnInit(): void {
    this.route.data.subscribe((resp: { listado: any[] }) => {
      this.data = JSON.parse(JSON.stringify(resp.listado)).respuesta;
    });
  }

  public descargaPDF(id: number) {
    this.servicio.descargaPDF(id).subscribe(
      response => {
        let blob: any = new Blob([response], { type: 'application/pdf; charset=utf-8' });
        const url = window.URL.createObjectURL(blob);
        window.open(url, '_blank');
      },
      err => {
        alert("Problemas al generar reporte");
      }
    )
  }

  public descargaXML(id: number) {
    this.servicio.descargaXML(id).subscribe(
      response => {
        let blob: any = new Blob([response], { type: 'application/xml; charset=utf-8' });
        const url = window.URL.createObjectURL(blob);
        window.open(url, '_blank');
      },
      err => {
        alert("Problemas al generar reporte");
      }
    )
  }

}
