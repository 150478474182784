import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthRoutingModule } from './auth/auth.routing';
import { PagesRoutingModule } from './pages/pages.routing';
import { PagesComponent } from './pages/pages.component';
import { GeneralComponent } from './pages/general/general.component';
import { IntegrantesComponent } from './pages/integrantes/integrantes.component';
import { EstadocuentaComponent } from './pages/estadocuenta/estadocuenta.component';
import { OpenpayComponent } from './pages/openpay/openpay.component';
import { GeneralResolver } from './pages/general/services/general.resolver';
import { PoliticasComponent } from './pages/politicas/politicas.component';
import { TerminosComponent } from './pages/terminos/terminos.component';
import { TerminosResolver } from './pages/terminos/services/terminos.resolver';
import { AvisosResolver } from './pages/politicas/services/avisos.resolver';
import { EstadocuentaResolver } from './pages/estadocuenta/services/estadocuenta.resolver';
import { ConfirmaPagoComponent } from './pages/confirma-pago/confirma-pago.component';
import { ReservacionComponent } from './pages/reservacion/reservacion.component';
import { ReciboResolver } from './pages/openpay/services/recibo.resolver';
import { ReservacionResolver } from './pages/reservacion/services/reservacion.resolver';
import { RechazoComponent } from './pages/rechazo/rechazo.component';
import { ContactoComponent } from './pages/contacto/contacto.component';
import { PlaneacionComponent } from './pages/planeacion/planeacion.component';
import { EmpresaResolver } from './pages/contacto/Services/Empresa.resolver';
import { ConfirmacionreservaComponent } from './pages/confirmacionreserva/confirmacionreserva.component';
import { TableroResolver } from './pages/tablero/services/tablero.resolver';
import { TableroComponent } from './pages/tablero/tablero.component';
import { ConfirmacionResolver } from './pages/confirma-pago/Services/Confirmacion.resolver';
import { RechazoResolver } from './pages/rechazo/Services/Rechazo.resolver';
import { CalificacionesComponent } from './pages/calificaciones/calificaciones.component';
import { LstNominaResolver } from './pages/calificaciones/Services/lstNomina.resolver';
import { ComprobantesComponent } from './pages/comprobantes/comprobantes.component';
import { ComprobantesResolver } from './pages/comprobantes/services/comprobantes.resolver';


const routes: Routes = [
  { path: '', redirectTo: '/pages/tablero', pathMatch: 'full' },
  {
    path: 'pages',
    component: PagesComponent,
    children: [
      {
        path: 'tablero',
        component: TableroComponent,
        pathMatch: 'full',
        resolve: { tablero: TableroResolver }
      },
      {
        path: 'calificaciones',
        component: CalificacionesComponent,
        pathMatch: 'full',
        resolve: { listado: LstNominaResolver },
      },
      {
        path: 'general',
        component: GeneralComponent,
        pathMatch: 'full',
        resolve: { membresia: GeneralResolver },
      },
      {
        path: 'integrantes',
        component: IntegrantesComponent,
        pathMatch: 'full',
      },
      {
        path: 'estadocuenta',
        component: EstadocuentaComponent,
        pathMatch: 'full',
        resolve: { listado: EstadocuentaResolver },
      },
      {
        path: 'comprobantes',
        component: ComprobantesComponent,
        pathMatch: 'full',
        resolve: { listado: ComprobantesResolver },
      },
      {
        path: 'politica',
        component: PoliticasComponent,
        pathMatch: 'full',
        resolve: { listado: AvisosResolver },
      },
      {
        path: 'terminos',
        component: TerminosComponent,
        pathMatch: 'full',
        resolve: { terminos: TerminosResolver },
      },
      {
        path: 'contacto',
        component: ContactoComponent,
        pathMatch: 'full',
        resolve: { info: EmpresaResolver },
      },
      {
        path: 'rechazo',
        component: RechazoComponent,
        pathMatch: 'full',
        resolve: { info: RechazoResolver },
      },
      {
        path: 'calendario',
        component: PlaneacionComponent,
        pathMatch: 'full'
      },
      {
        path: 'reservacion',
        component: ReservacionComponent,
        pathMatch: 'full',
        resolve: { reservacion: ReservacionResolver }
      },
      {
        path: 'confirmapago',
        component: ConfirmaPagoComponent,
        pathMatch: 'full',
        resolve: { info: ConfirmacionResolver },
      },
      {
        path: 'openpay/:id',
        component: OpenpayComponent,
        pathMatch: 'full',
        resolve: {
          recibo: ReciboResolver
        },
      },
      {
        path: 'confirmacionreserva',
        component: ConfirmacionreservaComponent,
        pathMatch: 'full'
      },
    ],
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
    AuthRoutingModule,
    PagesRoutingModule,
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
