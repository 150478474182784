<div class="row clsTitulo">
  <div class="col-10">
    <h2>Reservaciones</h2>
  </div>
  <div class="col-2">
    <div class="icono">
      <i class="fas fa-calendar-alt"></i>
    </div>
  </div>
</div>
<ul class="nav nav-tabs" role="tablist">
  <li class="nav-item">
    <a class="nav-link active" id="tab_proceso-tab" data-toggle="tab" href="#tab_proceso" role="tab"
      aria-controls="tab_proceso" aria-selected="true">Proceso</a>
  </li>
  <li class="nav-item">
    <a class="nav-link" id="tab_ayuda-tab" data-toggle="tab" href="#tab_ayuda" role="tab" aria-controls="tab_ayuda"
      aria-selected="false">Ayuda</a>
  </li>
</ul>
<div class="tab-content">
  <div class="tab-pane fade show active" id="tab_proceso" role="tabpanel" aria-labelledby="tab_proceso-tab">
    <div class="tab-content">
      <div *ngIf="titular">
        <div class="callout callout">
          <h6><b>Integrantes de la Membresia</b></h6>
        </div>
        <ejs-grid [dataSource]='data' gridLines='Both' [rowHeight]='20' (rowSelected)="onRowSelected($event)">
          <e-columns>
            <e-column field='id' headerText='id' width='0'></e-column>
            <e-column field='id_clb_membresia' headerText='id membresia' width='0'></e-column>
            <e-column field='codigo_extra' headerText='Codigo Extra' width='150'></e-column>
            <e-column field='nombre' headerText='Nombre' width='250'></e-column>
            <e-column field='cargo' headerText='Parentesco' width='150'></e-column>
            <e-column field='departamento' headerText='Departamento' width='150'></e-column>
            <e-column field='estatus' headerText='Estatus' width='150'></e-column>
          </e-columns>
        </ejs-grid>
        <br>
        <button #btnreservar type="button" class="btn btn-procesar rounded-0 mb-2 mr-2" [disabled]="!showbutton"
          (click)="show = !show" ng-model="show">Reservar</button>
      </div>
      <div *ngIf="!titular || titular">
        <div class="row container-fluid" *ngIf="show" id="divshow">
          <div class="callout callout">
            <h6><b>Sistema Reservaciónes</b></h6>
          </div>
          <div class="col-lg-12 control-section e-tab-section">
            <div class="e-sample-resize-container">
              <form autocomplete="off" [formGroup]='formulario'>
                <ejs-tab #tab id="tab_wizard" height=auto width=100% showCloseButton=false
                  (selecting)="tabSelecting($event)">
                  <e-tabitems>
                    <e-tabitem [header]='headerText[0]'>
                      <ng-template #content>
                        <div class="details-page wizard-title">Buscar sección</div>
                        <div id='seccion'>
                          <div class="form-group">
                            <label for="seccion">Secciones disponibles</label>
                            <ejs-dropdownlist id='seccion' #secciones [dataSource]='data_seccion'
                              [fields]='fields_seccion' placeholder='Seleccionar seccion'
                              (change)='onChangeSeccion($event)'>
                            </ejs-dropdownlist>
                          </div>
                          <!-- BOTONES -->
                          <div class="btn-container">
                            <button type="button" id="division" class="btn btn-convertir rounded-0 mb-2 mr-2"
                              (click)='btnClicked($event)'>Continuar</button>
                          </div>
                          <!-- FIN BOTONES -->
                          <span id="err1"></span>
                        </div>
                      </ng-template>
                    </e-tabitem>
                    <e-tabitem [header]='headerText[1]' disabled=true>
                      <ng-template #content>
                        <div id='division'>
                          <div class="details-page wizard-title">Division</div>
                          <div class="form-group">
                            <label for="division">Divisiones disponibles</label>
                            <ejs-dropdownlist id='division' #divisiones [dataSource]='data_division'
                              [fields]='fields_division' placeholder='Seleccionar division'
                              (change)='onChangeDivision($event)' (click)='onChangeDivision($event)'>
                            </ejs-dropdownlist>
                          </div>
                          <!-- BOTONES -->
                          <div class="btn-container">
                            <button type="button" id="regresararea" class="btn btn-convertir rounded-0 mb-2 mr-2"
                              (click)='btnClicked($event)'>Regresar</button>
                            <button type="button" id="horarios" class="btn btn-convertir rounded-0 mb-2 mr-2"
                              (click)='btnClicked($event)'>Continuar</button>
                          </div>
                          <!-- FIN BOTONES -->
                        </div>
                        <span id="err2"></span>
                      </ng-template>
                    </e-tabitem>
                    <e-tabitem [header]='headerText[2]' disabled=true>
                      <ng-template #content>
                        <div id='horarios'>
                          <div class="details-page wizard-title">Horarios disponibles</div>
                          <div class="form-group">
                            <label for="horario">Horarios</label>
                            <ejs-dropdownlist id='horario' #horarios [dataSource]='data_horario'
                              [fields]='fields_horario' placeholder='Seleccionar horario' (change)='onCambios($event)'>
                            </ejs-dropdownlist>
                          </div>
                          <div>
                            <form [formGroup]="formulario">
                              <div class="row">
                                <div class="col-md-12 col-lg-6 mb-4 align-items-stretch">
                                  <div class="row" style="display: flex;">
                                    <div class="col-md-6">
                                      <div class="form-group">
                                        <ejs-textbox hidden="true" type="text" #contacto id="nombrecontacto"
                                          formControlName='id_clb_membresia_contacto' readonly></ejs-textbox>
                                        <ejs-textbox hidden="true" type="text" #contactoname id="nombrecontacto"
                                          readonly>
                                        </ejs-textbox>

                                      </div>
                                    </div>
                                    <div class="col-md-6">
                                      <div class="form-group">
                                        <ejs-textbox hidden="true" type="text" #instructor id="nombrecontacto"
                                          formControlName='id_crm_instructor' readonly>
                                        </ejs-textbox>
                                        <ejs-textbox hidden="true" type="text" #instructorname id="nombrecontacto"
                                          readonly>
                                        </ejs-textbox>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="row" style="display: flex;">
                                    <div class="col-md-6">
                                      <div class="form-group">
                                        <ejs-textbox hidden="true" type="text" #entrada id="nombrecontacto"
                                          formControlName='entrada' readonly>
                                        </ejs-textbox>
                                      </div>
                                    </div>
                                    <div class="col-md-6">
                                      <div class="form-group">
                                        <ejs-textbox hidden="true" type="text" #salida id="nombrecontacto"
                                          formControlName='salida' readonly></ejs-textbox>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="row" style="display: flex;">
                                    <div class="col-md-6">
                                      <div class="form-group">
                                        <!-- <label for="nombrecontacto">EN LINEA</label> -->
                                        <ejs-textbox hidden="true" type="text" #linea id="nombrecontacto"
                                          formControlName='enlinea' readonly></ejs-textbox>
                                      </div>
                                    </div>
                                    <div class="col-md-6">
                                      <div class="form-group">
                                        <!-- <label for="nombrecontacto">ID DE DIVISION</label> -->
                                        <ejs-textbox hidden="true" type="text" #division id="nombrecontacto"
                                          formControlName='id_clb_seccion_division' readonly></ejs-textbox>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                          <!-- BOTONES -->
                          <div class="btn-container">
                            <button type="button" id="regresardivision" class="btn btn-convertir rounded-0 mb-2 mr-2"
                              (click)='btnClicked($event)'>Regresar</button>
                            <button type="button" id="confirmacion" class="btn btn-convertir rounded-0 mb-2 mr-2"
                              (click)='btnClicked($event)' (click)='onCambios($event)'>Continuar</button>
                          </div>
                          <!-- FIN BOTONES -->
                        </div>
                        <span id="err3"></span>
                      </ng-template>
                    </e-tabitem>
                  </e-tabitems>
                </ejs-tab>
              </form>
            </div>
          </div>
        </div>
      </div>

      <ejs-dialog id='dialog' #dlgReservacionCompleta width='50%' height='auto' isModal='true' [visible]='false'
        showCloseIcon='true'>

      </ejs-dialog>
    </div>



  </div>
  <div class="tab-pane fade" id="tab_ayuda" role="tabpanel" aria-labelledby="tab_ayuda-tab">
    <!-- <span>Ayuda</span> -->



  </div>

</div>
