import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CheckBoxComponent } from '@syncfusion/ej2-angular-buttons';
import {
  GridComponent,
  SelectionSettingsModel,
} from '@syncfusion/ej2-angular-grids';
import { DialogUtility } from '@syncfusion/ej2-angular-popups';
import { MdlUsuario } from 'src/app/auth/models/MdlUsuario';
import { EstadocuentaService } from './services/estadocuenta.service';

@Component({
  selector: 'app-estadocuenta',
  templateUrl: './estadocuenta.component.html',
  styleUrls: ['./estadocuenta.component.scss'],
})
export class EstadocuentaComponent implements OnInit {
  // ID de la inscripcion actual del alumno
  public _ID: number = -1;
  // creamos este id para sacar el id del documento y procesarlo a pago
  public id_documento: number = -1;
  public dsAdeudos: any;
  public dsHistorial: any;
  public ImporteTotal: number = 0;
  public usuario: MdlUsuario;
  @ViewChild('tbAdeudos') tbAdeudos: GridComponent;
  @ViewChild('importefinal') importefinal: ElementRef;
  @ViewChild('checkbox') checkbox: CheckBoxComponent;
  public id: number = -1;
  public selectionOptions: SelectionSettingsModel = {
    type: 'Multiple',
    enableSimpleMultiRowSelection: true,
  };
  constructor(
    private Services: EstadocuentaService,
    private router: Router
  ) {
    // asignamos el id de localstorange
    this._ID = (JSON.parse(localStorage.getItem('usuario')).id);
    // nos aceguramos de estar vacios
    this.dsAdeudos = '';
    this.dsHistorial = '';
    // id de la membresia localStorage se debe importar modelo en consola
    this.usuario = JSON.parse(localStorage.getItem('usuario'));
    this.Services.getTabla(this._ID, true).subscribe((resp) => {
      this.dsAdeudos = resp.respuesta;
    });
    this.Services.getTabla(this._ID, true).subscribe((resp) => {
      this.dsHistorial = resp.respuesta;
    });
  }

  ngOnInit(): void {
    this.Services.getTablaPago(this._ID).subscribe((resp) => {
      this.dsAdeudos = resp.respuesta;
    });
  }

  public onRowSelected(args: any) {
    //  asignamos el valor a id_documento: son los documentos de la tabla
    this.id_documento = args.data.id
    this.ImporteTotal = 0.0;
    let registros: any = this.tbAdeudos.getSelectedRecords();
    registros.forEach((registro) => {
      this.ImporteTotal += registro.importe;
    });
    this.importefinal.nativeElement.value = this.ImporteTotal.toPrecision(6);
  }

  /**
   * hacemos un checkchage para ejecutar el historial
   * @param args
   */
  checkboxChange(args: any) {

  }
  // aseguramos en dejar vacias las tablas
  ngOnDestroy(): void {
    this.dsAdeudos = '';
    this.dsHistorial = '';
  }
  /**
   * creamos un metodo para al momento de volver a seleccionar seleccione nada y quede vacio
   * @param args
   */
  public rowDeselected(args: any): void {
    this.ImporteTotal = 0.0;
    let registros: any = this.tbAdeudos.getSelectedRecords();
    registros.forEach((registro) => {
      this.ImporteTotal += registro.importe;
    });
  }

  // function to handle the CheckBox change event
  // public changeHandler(): void {
  //   if (this.checkbox.checked === true) {
  //     this.Services.getTabla(this._ID, false).subscribe((resp) => {
  //       this.dsHistorial = resp.respuesta;
  //     });
  //   }
  //   // aseguro mi trablaacia al momento de activar el check
  //   this.dsHistorial = '';
  // }

  btnPagar(id: any) {
    if (this.id_documento < 0) {
      DialogUtility.alert({
        title: '<span class="dialogo-titulo-error">' + 'Información Arcarius [Problemas]' + ' <i style="color: #fff" class="fas fa-ban"></i></span>',
        content: '<h6>' + '[ Selecciona un Adeudo a pagar  <i style="color: #FF0033" class="fas fa-exclamation-triangle"></i>    ]' + '</h6>',
        showCloseIcon: true,
        closeOnEscape: true,
        animationSettings: { effect: 'Zoom' },
      });
      return;
    }
    else {
      // igualamos el id
      id = this.id_documento
      /**pagamos con varios documentos aqui se hace la trasformacion de memebresias */
      // let registros: any = this.tbAdeudos.getSelectedRecords();
      // let ids: string = '';
      // registros.forEach((registro) => {
      //   ids += ',' + registro.id;
      // });
      this.Services.getAdeudo(String(this.id_documento), id).subscribe(
        (resp) => {
          if (id > 0) {
            this.router.navigate(['pages/openpay', id]);
          } else {
            DialogUtility.alert({
              title: '<span style="color: #28a745">' + resp.titulo + ' <i style="color: #28a745" class="fas fa-check-double"></i></span>',
              content: JSON.parse(JSON.stringify(resp)).mensaje,
              showCloseIcon: true,
              closeOnEscape: true,
              animationSettings: { effect: 'Zoom' }
            });
          }
        }
      );
    }
  }
}