import { Directive, ElementRef, HostListener } from '@angular/core';

const TIEMPO = 500;

@Directive({
  selector: 'button[n-submit]'
})
export class DobleClickDirective {
  constructor(private elementRef: ElementRef) { }
  @HostListener('click', ['$event'])
  clickEvent() {
    console.log('Arcarius');
    this.elementRef.nativeElement.setAttribute('disabled', 'true');
    setTimeout(() => this.elementRef.nativeElement.removeAttribute('disabled'), TIEMPO);
  }
}