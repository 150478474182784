import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { ErroresService } from 'src/app/shared/errores.service';

@Injectable({
  providedIn: 'root'
})
export class TableroResolver implements Resolve<boolean> {
  constructor(private http: HttpClient,private errores: ErroresService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> | any {
    // ====================================================================================
    // Cargamos los parametros proporcionados para el tablero, en caso de no existir
    // cargamos el tablero por default del usuario autenticado. rh_empleado.id_rpt_tablero_detalle
    // ====================================================================================
    const usuario = localStorage.getItem('usuario');
    let parametro: HttpParams = new HttpParams();
    if (usuario != null) {
      parametro = new HttpParams()
        .append('id', '22')
        .append('id2', JSON.parse(usuario).id_pe_inscripcion);
    } else {
      parametro = new HttpParams()
        .append('id', '22')
        .append('id2', '-1');
    }
    return this.http.get<any>(`${environment.base_url}/tablero/tablero2`, { params: parametro })
    .pipe(
      catchError(error => {
        return throwError(this.errores.getErrores(error));
      })
    );
  }
}
