import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ErroresService } from 'src/app/shared/errores.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LstNominaResolver implements Resolve<boolean> {
  constructor(private http: HttpClient, private router: Router, private errores: ErroresService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> | any {
    return this.http.get<any>(`${environment.base_url}/escolar/control/alumno/lstCalificaciones`)
    .pipe(
      catchError(error => {
        return throwError(this.errores.getErrores(error));
      })
    );
  }

}
