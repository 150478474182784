import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { MdlMembresia } from './models/MdlMembresia';
import { GeneralService } from './services/general.service';
import { MdlUsuario } from 'src/app/auth/models/MdlUsuario';

@Component({
  selector: 'app-general',
  templateUrl: './general.component.html',
  styleUrls: ['./general.component.scss']
})
export class GeneralComponent implements OnInit {
  public formulario:  UntypedFormGroup = new UntypedFormGroup({});
  public format: string = 'dd/MM/yyyy';
  // listbox
  public lstEstadoBox: any;
  public lstMunicipioBox: any;
  public lstPaisBox: any;
  public lstClasificaBox: any;
  public lstEstatusBox: any;
  public clientenombre: any;
  // config listbox
  public fields: Object = { text: 'descripcion', value: 'id' };
  public usuario: MdlUsuario;

  constructor(
    private route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private Services: GeneralService,


  ) {
    // lisbox carga de informacion
    this.Services.lstMunicipioBox().subscribe(resp => { this.lstMunicipioBox = resp.respuesta; });
    this.Services.lstEstadoBox().subscribe(resp => { this.lstEstadoBox = resp.respuesta; });
    // this.Services.lstPaisBox().subscribe(resp => { this.lstPaisBox = resp.respuesta; });
    this.Services.lstClasificaBox().subscribe(resp => { this.lstClasificaBox = resp.respuesta; });
    this.Services.lstEstatusBox().subscribe(resp => { this.lstEstatusBox = resp.respuesta; });
    // id de la membresia localStorage se debe importar modelo en consola
    this.usuario = JSON.parse(localStorage.getItem('usuario'));
    this.Services.getCliente( this.usuario.id_clb_membresia).subscribe(resp => {
      this.clientenombre = resp.respuesta.nombre;
   });
    this.route.data
      .subscribe((resp: { membresia: any[] }) => {
        this.formulario = this.formBuilder.group(JSON.parse(JSON.stringify(resp.membresia)).respuesta as MdlMembresia);
        // let membresia: MdlMembresia = resp.membresia;
        // this.formulario = this.formBuilder.group(membresia);
      });
    
  }
  ngOnInit(): void {}
}
