import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { GeneralComponent } from './general/general.component';
import { IntegrantesComponent } from './integrantes/integrantes.component';
import { EstadocuentaComponent } from './estadocuenta/estadocuenta.component';
import { OpenpayComponent } from './openpay/openpay.component';
import { TerminosComponent } from './terminos/terminos.component';
import { PoliticasComponent } from './politicas/politicas.component';
import { ConfirmaPagoComponent } from './confirma-pago/confirma-pago.component';
import { PagesRoutingModule } from './pages.routing';
import { RechazoComponent } from './rechazo/rechazo.component';
import { ContactoComponent } from './contacto/contacto.component';
import { CommonModule } from '@angular/common';
import { PlaneacionComponent } from './planeacion/planeacion.component';
import { ConfirmacionreservaComponent } from './confirmacionreserva/confirmacionreserva.component';
import { TableroComponent } from './tablero/tablero.component';
import { ReservacionComponent } from './reservacion/reservacion.component';
import { CalificacionesComponent } from './calificaciones/calificaciones.component';
import { ReporteComponent } from './reporte/reporte.component';
import { ComprobantesComponent } from './comprobantes/comprobantes.component';

@NgModule({
  declarations: [
    GeneralComponent,
    IntegrantesComponent,
    EstadocuentaComponent,
    OpenpayComponent,
    TerminosComponent,
    PoliticasComponent,
    ConfirmaPagoComponent,
    RechazoComponent,
    ContactoComponent,
    PlaneacionComponent,
    ConfirmacionreservaComponent,
    TableroComponent,
    ReservacionComponent,
    CalificacionesComponent,
    ReporteComponent,
    ComprobantesComponent
  ],

  imports: [
    SharedModule,
    CommonModule,
    PagesRoutingModule
  ],
  providers: [

  ],
  //===============================================================================================
  // La siguiente linea remueve los errores de sintaxis del los componentes web, en los archivos
  // html de cada componente. Esto para los componentes importados de terceros SyncFusion.
  //===============================================================================================
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
  //===============================================================================================
})
export class PagesModule { }
