import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Browser } from '@syncfusion/ej2-base';
import { ITooltipRenderEventArgs } from '@syncfusion/ej2-charts';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-tablero',
  templateUrl: './tablero.component.html',
  styleUrls: ['./tablero.component.scss']
})
export class TableroComponent implements OnInit {
  public aspectRatio: any = 100 / 80;
  public cellSpacing: number[] = [10, 10];
  public width: string = Browser.isDevice ? '100%' : '60%';
  public dataChico: any;
  public dataMediano: any;
  public dataGrande: any;

  public marker: Object = {
    visible: true,
    width: 10,
    height: 10,
    dataLabel: {
      visible: false,
      position: 'Top',
      font: {
        size: '16',
        fontWeight: 'normal'
      },
      border: { width: 1, color: 'white' },
      margin: { left: 5, right: 5, top: 2, bottom: 2 },
      //template: '<div style="font-size: 16px;">${point.y}</div>'
    }
  };
  public chartArea: Object = { border: { width: 0 } };
  public enableAnimation: boolean = true;
  public legendSettings: Object = { visible: true };
  public tooltip: Object = { enable: true, format: '${point.x} : <b>${point.y}</b>' };
  //public tooltip: Object = { enable: true };

  //===============================================================================================
  // Configuracion de Ejes
  //===============================================================================================
  public primaryXAxis: Object = {
    valueType: 'Category',
    interval: 1,
    labelIntersectAction: 'Rotate45',
    majorGridLines: { width: 0 },
    labelStyle: {
      fontFamily: 'Roboto',
      fontStyle: 'medium',
      size: '14px'
    }
  };

  public primaryYAxis: Object = {
    labelFormat: 'n2',
    rangePadding: 'None',
    //minimum: 0,
    //maximum: 100,
    //interval: 20,
    lineStyle: { width: 0 },
    majorTickLines: { width: 0 },
    minorTickLines: { width: 0 }
  };

  public tooltipRender(args: ITooltipRenderEventArgs): void {
    let textCol = args.text.split(":");
    let str1 = textCol[1].replace('<b>', '');
    let str2 = str1.replace('</b>', '');
    var value = parseFloat((str2).replace(',', ''));
    args.text = textCol[0] + ": <b>" + (value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') + '</b>';
  };
  //===============================================================================================


  constructor(
    private route: ActivatedRoute,
    private http: HttpClient,
  ) { }

  ngOnInit(): void {
    this.route.data.subscribe((resp: { tablero: any[] }) => {
      this.dataChico = JSON.parse(JSON.stringify(resp.tablero)).respuesta.chico;
      this.dataMediano = JSON.parse(JSON.stringify(resp.tablero)).respuesta.mediano;
      this.dataGrande = JSON.parse(JSON.stringify(resp.tablero)).respuesta.grande;
    });
  }

  onReporte(id: number, parametros: any) {
    // ================================================================================
    // Ejecutamos el reporte con los parametros proporcionados
    // ================================================================================
    let resonseType: any = 'blob';
    let headers: HttpHeaders = new HttpHeaders({ "Content-Type": "application/json" });
    this.http.post<any>(`${environment.base_url}/reporte/reporte/${id}`, JSON.stringify(parametros), { responseType: resonseType, headers: headers })
      .subscribe(
        response => {
          let blob: any = new Blob([response], { type: 'application/pdf; charset=utf-8' });
          const url = window.URL.createObjectURL(blob);
          window.open(url, '_blank');
        },
        err => {
          alert("Problem while downloading the file.");
        }
      );
  }

}
