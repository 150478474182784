<div class="row clsTitulo">
    <div class="col-10">
        <h2>Calificaciones</h2>
    </div>
    <div class="col-2">
        <div class="icono">
            <i class="fas fa-chalkboard-teacher"></i>
        </div>
    </div>
</div>
<ul class="nav nav-tabs" role="tablist">
    <li class="nav-item">
        <a class="nav-link active" id="tab_proceso-tab" data-toggle="tab" href="#tab_proceso" role="tab" aria-controls="tab_proceso" aria-selected="true">Proceso</a>
    </li>
    <li class="nav-item">
        <a class="nav-link" id="tab_ayuda-tab" data-toggle="tab" href="#tab_ayuda" role="tab" aria-controls="tab_ayuda" aria-selected="false">Ayuda</a>
    </li>
</ul>
<div class="tab-content">
    <div class="tab-pane fade show active" id="tab_proceso" role="tabpanel" aria-labelledby="tab_proceso-tab">
        <block-ui>
            <!-- Contenido principal -->
            <div class="container" style="padding: 10px;">
                <div class="row">
                    <div class="col shadow p-3 mb-2 bg-body rounded" style="margin-right: 10px; margin-left: 10px;">
                        <h5>Consulta de boletas.</h5>
                    </div>
                </div>

                <!-- tabla de calificaciones -->
                <div class="row">
                    <div class="col shadow p-3 mb-2 bg-body rounded" style="margin-right: 10px; margin-left: 10px;">

                        <table style="margin-left: 10px;  width: 100%;">
                            <tr *ngFor="let item of data" style="margin-left: 15px;">
                                <td class="celdaDescripcion">
                                    <i class="fas fa-bolt" style="color: #fd7e14;"></i> {{item.calificaciones}}
                                </td>
                                <td class="celdaArchivo">
                                    <button n-submit type="button" title="Imprimir boleta de califiaciones" class="btn btn-outline-success" (click)="onMostrarPdf(item.id_pe_inscripcion, item.id_rpt_encabezado)">
                                        <i class="far fa-file-pdf"> PDF</i>
                                    </button>
                                </td>
                            </tr>
                        </table>

                    </div>
                </div>
                <!-- div cierre de container -->
            </div>
        </block-ui>
    </div>
    <!-- cierre de tab proceso -->
    <div class="tab-pane fade" id="tab_ayuda" role="tabpanel" aria-labelledby="tab_ayuda-tab">
        <!-- TITULO de la ventana -->
        <div class="callout callout-titulo">
            Calificaciones
            <div class="subtitulo">
                Sección destinada a vizualización de las calificaciones obtenidas por el alumno.
            </div>
        </div>
    </div>
</div>