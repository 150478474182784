<aside class="sidebar shadow-sm">

    <div class="sidebar-profile d-flex flex-column justify-content-center pt-5 pb-3">
        <div class="profile-details text-center pb-4">
            <p class="mb-0 text-uppercase name">
                {{usuario.empresa}}
            </p>
        </div>
        <div class="picture-wrapper mb-4">
            <div class="user-picture m-auto">
                <!-- <img src="" class="img-fluid" alt=""> -->
                <img id="PerfilImagen" [src]="imagen" class="rounded float-right" width="100" height="100">
            </div>
        </div>
        <div class="profile-details text-center pb-4">
            <p class="mb-0 text-uppercase name">{{usuario.nombre}}</p>
            <small class="text-uppercase role">{{usuario.paterno}} {{usuario.materno}}</small>
            <br />
            <small class="text-uppercase role">{{usuario.departamento}}</small>
        </div>
    </div>
    <div class="sidebar-search pt-1 pb-1 pl-3 pr-3">
        <input type="search" class="form-control rounded-pill" name="search" placeholder="xxx ...">
    </div>
    <div *ngIf="cargando" id="cargador" class="align-items-center" style="padding: 3px 10px; display: flex;">
        <strong>Cargando...</strong>
        <div class="spinner-border ml-auto" role="status" aria-hidden="true"></div>
    </div>
    <nav class="sidebar-menu pt-3 pb-3">
        <div class="menu-header mt-3 mb-2">
            Accesos
        </div>
        <ul class="menu-items">
            <li>
                <a href="#" routerLink="/pages/calificaciones" routerLinkActive="active">
                    <i class="fas fa-home"></i>
                    <span>Calificaciones</span>
                    <span class="badge badge-warning rounded-0 text-dark p-1"></span>
                </a>
            </li>
            <li>
                <a href="#" routerLink="/pages/tablero" routerLinkActive="active">
                    <i class="fas fa-home"></i>
                    <span>Home</span>
                    <span class="badge badge-warning rounded-0 text-dark p-1"></span>
                </a>
            </li>
            <li>
                <a href="#" routerLink="/pages/estadocuenta" routerLinkActive="active">
                    <i class="fas fa-money-check-alt"></i>
                    <span>Estado Cuenta</span>
                    <span class="badge badge-warning rounded-0 text-dark p-1"></span>
                </a>
            </li>
            <li>
                <a href="#" routerLink="/pages/comprobantes" routerLinkActive="active">
                    <i class="fas fa-list"></i>
                    <span>Comprobantes</span>
                    <span class="badge badge-warning rounded-0 text-dark p-1"></span>
                </a>
            </li>
        </ul>
    </nav>
</aside>