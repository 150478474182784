import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { ComprobantesService } from './comprobantes.service';

@Injectable({
  providedIn: 'root'
})
export class ComprobantesResolver implements Resolve<boolean> {

  constructor(
    private servicio: ComprobantesService
  ) {

  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.servicio.getListadoComprobantes();
  }
}
